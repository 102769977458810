import React,{useState, useEffect, useRef} from 'react'; 
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { userCreation, checkUserType } from "../../Utils/Urls";
import './UserForm.css'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from 'primereact/dropdown';
import { useParams } from 'react-router-dom';
import { InputMask } from "primereact/inputmask";
import { useLocation } from 'react-router-dom';

export default function UserForm() {

    const {locationId} = useParams()
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const toast = useRef(null);
    const [selectedRole, setselectedRole] = useState({ name: 'User', code: 'US' });
    const roles = [
        { name: 'Admin', code: 'AD' },
        { name: 'User', code: 'US' }
    ];
    const [selectedType, setselectedType] = useState({ name: 'Account', code: 'AC' });
    const types = [
        { name: 'Agency', code: 'AG' },
        { name: 'Account', code: 'AC' }
    ];
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [extension, setextension] = useState('');
    const [password, setpassword] = useState('');
    const [dashbaordStats, setDashbaordStats] = useState(true);
    const [appointments, setAppointments] = useState(true);
    const [bulkRequests, setBulkRequests] = useState(true);
    const [funnels, setFunnels] = useState(false);
    const [opportunities, setOpportunities] = useState(false);
    const [conversations, setConversations] = useState(true);
    const [contacts, setContacts] = useState(true);
    const [reviews, setReviews] = useState(true);
    const [onlineListings, setOnlineListings] = useState(false);
    const [settings, setSettings] = useState(false);
    const [onlyAssignedData, setOnlyAssignedData] = useState(false);
    const [tags, setTags] = useState(false);
    const [leadValue, setLeadValue] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [adwordsReporting, setAdwordsReporting] = useState(false);
    const [facebookAdsReporting, setFacebookAdsReporting] = useState(false);
    const [callReporting, setCallReporting] = useState(true);
    const [attributionReporting, setAttributionReporting] = useState(false);
    const [agentReporting, setAgentReporting] = useState(true);
    const [socialPlanner, setSocialPlanner] = useState(true);
    const [workflows, setWorkflows] = useState(false);
    const [workflowsReadOnly, setWorkflowsReadOnly] = useState(false);
    const [blogging, setBlogging] = useState(false);
    const [affiliateManager, setAffiliateManager] = useState(false);
    const [contentAI, setContentAI] = useState(true);
    const [payments, setPayments] = useState(false);
    const [recordPayment, setRecordPayment] = useState(false);
    const [invoicing, setInvoicing] = useState(false);
    const [paymentRefund, setPaymentRefund] = useState(false);
    const [cancelSubscription, setCancelSubscription] = useState(false);
    const [membership, setMembership] = useState(false);
    const [campaigns, setCampaigns] = useState(false);
    const [campaignsReadOnly, setCampaignsReadOnly] = useState(false);
    const [triggers, setTriggers] = useState(false);
    const [websites, setWebsites] = useState(false);
    const [phoneCall, setPhoneCall] = useState(true);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isAgencyAdmin, setIsAgencyAdmin] = useState(false)

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const email = params.get('email');
        if (email){
            const lookup_data = {
                locationId,
                email
            }

            axios.post(checkUserType, lookup_data,{
                headers: {'Content-Type': 'application/json'},
              })
            .then((response)=>{
                if (response.status === 200){
                    setIsAgencyAdmin(true)
                    setPageLoading(false)
                }
                else{
                    setIsAgencyAdmin(false)
                    setPageLoading(false)
                }
            })
            .catch((error)=>{
                setIsAgencyAdmin(false)
                setPageLoading(false)
            })
        }
        else{
            setIsAgencyAdmin(false)
            setPageLoading(false)
        }
        
      }, [location.search]);

    const handlePasswordVisible = () =>{
        setPasswordVisible(!passwordVisible)
    }

    function isPasswordValid(password) {
        // Regular expressions for each rule
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
      
        // Check if the password meets all the rules
        const isLengthValid = password.length >= 8;
        const hasUppercase = uppercaseRegex.test(password);
        const hasLowercase = lowercaseRegex.test(password);
        const hasDigit = digitRegex.test(password);
        const hasSpecialChar = specialCharRegex.test(password);
      
        // Return true if all rules are met, otherwise false
        return isLengthValid && hasUppercase && hasLowercase && hasDigit && hasSpecialChar;
      }

    const handleFormSubmit = async (e) =>{
        e.preventDefault();

        const trimmedFirstName = firstName ? firstName.trim() : null;
        const trimmedLastName = lastName ? lastName.trim() : null;
        const trimmedEmail = email ? email.trim() : null;
        const trimmedPassword = password ? password.trim() : null;
        const trimmedPhone = phone ? phone.trim() : null;
        const isValidPhoneNumber = trimmedPhone && /^\d{10}$/.test(trimmedPhone);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (trimmedFirstName == null || trimmedFirstName === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter the first name', life: 2000});
        } else if (trimmedLastName == null || trimmedLastName === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter the last name', life: 2000});
        } else if (trimmedEmail === null || trimmedEmail === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter the email', life: 2000});
        } else if (!emailRegex.test(trimmedEmail)) {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter a valid email', life: 2000});
        } else if (trimmedPassword == null || trimmedPassword === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter the password', life: 2000});
        } else if (isValidPhoneNumber){
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter a valid phone', life: 2000});
        }
        else{

            const isValid = isPasswordValid(password);

            if (isValid) {
                setLoading(true);
                const userData = {
                    locationId,
                    selectedRole,
                    selectedType,
                    firstName,
                    lastName,
                    email,
                    phone,
                    extension,
                    password,
                    permissions: {
                        dashbaordStats,
                        appointments,
                        bulkRequests,
                        funnels,
                        opportunities,
                        conversations,
                        contacts,
                        reviews,
                        onlineListings,
                        settings,
                        onlyAssignedData,
                        tags,
                        leadValue,
                        marketing,
                        adwordsReporting,
                        facebookAdsReporting,
                        callReporting,
                        attributionReporting,
                        agentReporting,
                        socialPlanner,
                        workflows,
                        workflowsReadOnly,
                        blogging,
                        affiliateManager,
                        contentAI,
                        payments,
                        recordPayment,
                        invoicing,
                        paymentRefund,
                        cancelSubscription,
                        membership,
                        campaigns,
                        campaignsReadOnly,
                        triggers,
                        websites,
                        phoneCall
                    },
                };
                axios.post(userCreation, userData,{
                    headers: {'Content-Type': 'application/json'},
                  })
                .then((response)=>{
                    if (response.status === 200){
                        setfirstName('');
                        setlastName('');
                        setemail('');
                        setphone('');
                        setextension('');
                        setpassword('');
                        setselectedRole({ name: 'Admin', code: 'AD' })
                        setLoading(false);
                        toast.current.show({severity:'success', summary: 'Success', detail:response.data, life: 3000});
                    }
                    else if (response.status === 207){
                        setLoading(false);
                        toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 3000});
                    }
                })
                .catch((error)=>{
                    setLoading(false);
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                })
              } 
              else {
                setLoading(false);
                toast.current.show({severity:'error', summary: 'Error', detail:'The password must be more than 8 characters including uppercase, lowercase, numbers and special characters', life: 3000});
              }

        }

    }

    const handleUserRoleChange = (e) =>{
        setselectedRole(e.value)
        if (e.value.name == 'Admin'){
            setOpportunities(true)
            setAgentReporting(true)
            setMarketing(true)
            setAttributionReporting(true)
        }
        else{
            setOpportunities(false)
            setAgentReporting(false)
            setMarketing(false)
            setAttributionReporting(false)
        }
    }

    return (
        <div className="card flex flex-column md:flex-row gap-3 user-card">
            <Toast ref={toast} />
            {pageLoading && (
            <div className="overlay"> 
                <i className="pi pi-spin pi-spinner " style={{ fontSize: '2rem' }}></i>
            </div>
            )}
            <form className='user-form'>
            <h1 className='form-header'>Team Management</h1>
                <div className="flex justify-content-center two-fields-flex">
                    <div className="flex flex-column gap-2 lable-input-group">
                        <label htmlFor="firstname">First Name *</label>
                        <InputText type='text' value={firstName} className='full-input' id="firstname" aria-describedby="firstname-help" onChange={(e) => setfirstName(e.target.value)} />
                    </div>

                    <div className="flex flex-column gap-2 lable-input-group">
                        <label htmlFor="lastname">Last Name *</label>
                        <InputText type='text' value={lastName}  className='full-input' id="lastname" aria-describedby="lastname-help" onChange={(e) => setlastName(e.target.value)}/>
                    </div>
                </div>

                <div className="flex justify-content-center two-fields-flex">
                    <div className="flex flex-column gap-2 email-lable-input-group">
                        <label htmlFor="email">Email *</label>
                        <InputText value={email} type='email' className='full-input' id="email" aria-describedby="email-help" onChange={(e) => setemail(e.target.value)}/>
                    </div>
                </div>

                <div className="flex justify-content-center two-fields-flex">
                    <div className="flex flex-column gap-2 lable-input-group phone-lable">
                        <label htmlFor="phone">Phone</label>
                        <InputMask id="phone" value={phone} className='full-input' mask="(999) 999-9999" onChange={(e) => setphone(e.target.value)}></InputMask>
                        {/* <InputText type='text' value={phone} id="phone" className='full-input' aria-describedby="phone-help" onChange={(e) => setphone(e.target.value)}/> */}
                    </div>

                    <div className="flex flex-column gap-2 lable-input-group extension-lable">
                        <label htmlFor="extension">Extension</label>
                        <InputText type='text' value={extension}  id="extension" className='full-input' aria-describedby="extension-help" onChange={(e) => setextension(e.target.value)}/>
                    </div>
                </div>

                <div className="flex justify-content-center two-fields-flex">
                    <div className="flex flex-column gap-2 email-lable-input-group">
                        <label htmlFor="password">Password *</label>
                        <span className="p-input-icon-right full-input">
                            <i className={`pi ${passwordVisible ? 'pi-eye' : 'pi-eye-slash'} password-icon`} onClick={handlePasswordVisible}/>
                            <InputText value={password}  type={`${passwordVisible ? 'text' : 'password'}`} className='full-input' id="password" aria-describedby="password-help" onChange={(e) => setpassword(e.target.value)}/>
                        </span>
                    </div>
                </div>
                {isAgencyAdmin && (
                    <div className="flex justify-content-center two-fields-flex">
                        <div className="flex flex-column gap-2 lable-input-group" style={{width:'45%'}}>
                            <label htmlFor="userrole">User Role</label>
                            <Dropdown value={selectedRole} onChange={handleUserRoleChange} options={roles} optionLabel="name" placeholder="User Role" className="w-full md:w-14rem full-input" />
                        </div>

                        <div className="flex flex-column gap-2 lable-input-group" style={{width:'45%'}}>
                            <label htmlFor="usertype">User Type</label>
                            <Dropdown value={selectedType} onChange={(e) => setselectedType(e.value)} options={types} optionLabel="name" placeholder="User Type" className="w-full md:w-14rem full-input" />
                        </div>
                    </div>
                )}
                {isAgencyAdmin && (
                    <Accordion className='accordation'>
                        <AccordionTab header="User Permissions">
                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label className="ml-2">Dashboard Stats</label>
                                <Checkbox onChange={e => setDashbaordStats(e.checked)} checked={dashbaordStats}/>
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Appointments</label>
                                <Checkbox onChange={e => setAppointments(e.checked)} checked={appointments}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Bulk Requests</label>
                                <Checkbox onChange={e => setBulkRequests(e.checked)} checked={bulkRequests}/>
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Funnels</label>
                                <Checkbox onChange={e => setFunnels(e.checked)} checked={funnels}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Opportunities</label>
                                <Checkbox onChange={e => setOpportunities(e.checked)} checked={opportunities} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Conversations</label>
                                <Checkbox onChange={e => setConversations(e.checked)} checked={conversations}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Contacts</label>
                                <Checkbox onChange={e => setContacts(e.checked)} checked={contacts}/>
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Reviews</label>
                                <Checkbox onChange={e => setReviews(e.checked)} checked={reviews}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Online Listings</label>
                                <Checkbox onChange={e => setOnlineListings(e.checked)} checked={onlineListings} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Settings</label>
                                <Checkbox onChange={e => setSettings(e.checked)} checked={settings}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Only Assigned Data</label>
                                <Checkbox onChange={e => setOnlyAssignedData(e.checked)} checked={onlyAssignedData}/>
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Tags</label>
                                <Checkbox onChange={e => setTags(e.checked)} checked={tags}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Lead Value</label>
                                <Checkbox onChange={e => setLeadValue(e.checked)} checked={leadValue} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Marketing</label>
                                <Checkbox onChange={e => setMarketing(e.checked)} checked={marketing}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Adwords Reporting</label>
                                <Checkbox onChange={e => setAdwordsReporting(e.checked)} checked={adwordsReporting} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Facebook Ads Reporting</label>
                                <Checkbox onChange={e => setFacebookAdsReporting(e.checked)} checked={facebookAdsReporting}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Call Reporting</label>
                                <Checkbox onChange={e => setCallReporting(e.checked)} checked={callReporting} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Attribution Reporting</label>
                                <Checkbox onChange={e => setAttributionReporting(e.checked)} checked={attributionReporting}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Agent Reporting</label>
                                <Checkbox onChange={e => setAgentReporting(e.checked)} checked={agentReporting}/>
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Social Planner</label>
                                <Checkbox onChange={e => setSocialPlanner(e.checked)} checked={socialPlanner}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Workflows</label>
                                <Checkbox onChange={e => setWorkflows(e.checked)} checked={workflows} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Workflows Read Only</label>
                                <Checkbox onChange={e => setWorkflowsReadOnly(e.checked)} checked={workflowsReadOnly}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Affiliate Manager</label>
                                <Checkbox onChange={e => setAffiliateManager(e.checked)} checked={affiliateManager} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Content AI</label>
                                <Checkbox onChange={e => setContentAI(e.checked)} checked={contentAI}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Payments</label>
                                <Checkbox onChange={e => setPayments(e.checked)} checked={payments}/>
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Record Payment</label>
                                <Checkbox onChange={e => setRecordPayment(e.checked)} checked={recordPayment}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Invoicing</label>
                                <Checkbox onChange={e => setInvoicing(e.checked)} checked={invoicing} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Payment Refund</label>
                                <Checkbox onChange={e => setPaymentRefund(e.checked)} checked={paymentRefund}/>
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Cancel Subscription</label>
                                <Checkbox onChange={e => setCancelSubscription(e.checked)} checked={cancelSubscription} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Membership</label>
                                <Checkbox onChange={e => setMembership(e.checked)} checked={membership} />
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Campaigns</label>
                                <Checkbox onChange={e => setCampaigns(e.checked)} checked={campaigns} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Campaigns Read Only</label>
                                <Checkbox onChange={e => setCampaignsReadOnly(e.checked)} checked={campaignsReadOnly} />
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Triggers</label>
                                <Checkbox onChange={e => setTriggers(e.checked)} checked={triggers} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Websites</label>
                                <Checkbox onChange={e => setWebsites(e.checked)} checked={websites} />
                            </div>
                        </div>

                        <div className="permission-row">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Phone Call</label>
                                <Checkbox onChange={e => setPhoneCall(e.checked)} checked={phoneCall} />
                            </div>
                            <div className="flex align-items-center lable-checkbox">
                            <div className="flex align-items-center lable-checkbox">
                                <label htmlFor="ingredient4" className="ml-2">Blogging</label>
                                <Checkbox onChange={e => setBlogging(e.checked)} checked={blogging}/>
                            </div>
                            </div>
                        </div>
                        </AccordionTab>
                    </Accordion>
                )}
                <div className='save-buttom'>
                    <Button label="Save" icon="pi pi-check" loading={loading} onClick={handleFormSubmit}/>
                </div>
                
            </form>
        </div>
    )
}