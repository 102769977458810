// export const baseUrl = "http://127.0.0.1:8000";
export const baseUrl = "https://programtrackingapi.simplexmarketingsystem.com";
export const ValidationUrl = "/validation";
export const AgencyValidationUrl = "/agency_validation";
export const allContactsUrl = "/contacts/";
export const updateWeight = "/update_weight";
export const addTag = "/add_contact_tag";
export const userCreation = "/add_user_v2";
export const checkUserType = "/check_user_type_v2";
export const syncUrl = "/sync/";
export const getTrackingUrl = "/get_tracking/";