import './App.css';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import ContactTable from './Components/ContactTable/ContactTable';
import OnboardForm from './Components/OnboardForm/OnboardForm';
import UserForm from './Components/UserCreationForm/UserForm';
import AgencyOnboardForm from './Components/OnboardForm/AgencyOnboardForm';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<OnboardForm/>} />
        <Route path="/agencyOnboarding" element={<AgencyOnboardForm/>} />
          <Route path="/contacts/:locationId" element={<ContactTable/>} />
          <Route path="/user/:locationId" element={<UserForm/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
