import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './table.css'
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { allContactsUrl, updateWeight, addTag, getTrackingUrl, syncUrl } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';

export default function ContactTable() {
    const {locationId} = useParams()
    const url = `${allContactsUrl}${locationId}`
    const trackUrl = `${getTrackingUrl}${locationId}`
    const syncingUrl = `${syncUrl}${locationId}`
    const [customers, setCustomers] = useState(null);
    const [selectedCell, setSelectedCell] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const [programs] = useState(['6 Week Challenge', '6 Week Challenge - MOC', '21 Day Program', '6 Week Challenge - Bodyfat v1', '6 Week Challenge - Bodyfat - MOC v1', '6 Week Challenge - Bodyfat v2', '6 Week Challenge - Bodyfat - MOC v2'])
    const [challenges] = useState(['Challenge 1', 'Challenge 2', 'Challenge 3', 'Challenge 4', 'Challenge 5', 'Challenge 6', 'Challenge 7', 'N/A | Not in Challenge or 21 Day', 'Program Participant Is Not Assigned To a Challenge # Yet'])
    const [rsvp] = useState(['Yes', 'No'])
    const [attended] = useState(['Yes', 'No'])
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(null);
    const [contactId, setContactId] = useState(null);
    const [field, setField] = useState(null);
    const [dialogHeader, setDialogHeader] = useState(null);
    const [hovered, setHovered] = useState(false);
    const [sync, setSync] = useState(false);
    const [trackIsMounted, setTrackIsMounted] = useState(false)
    const dt = useRef(null);

    useEffect(()=>{
        // setLoading(true);
        axios.get(url, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data);
                    setLoading(false);
                    initFilters();
                  }
                })
            .catch((error)=>{
                setLoading(false);
                setCustomers(null)
                
            })
    },[url, isMounted])

    useEffect(()=>{
        axios.get(trackUrl, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    if (response.data.status === 'completed'){
                        setSync(false)
                    }
                    else{
                        setSync(true)
                    }
                }
            })
    },[trackUrl, trackIsMounted])

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getSeverity = (value) => {
        switch (value) {
            case 'Yes':
                return 'success';

            case 'No':
                return 'danger';

            default:
                return null;
        }
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            program: { value: null, matchMode: FilterMatchMode.IN },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            challenge_assignment: { value: null, matchMode: FilterMatchMode.IN },
            notes: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            seminar_rsvp: { value: null, matchMode: FilterMatchMode.IN },
            attended_seminar: { value: null, matchMode: FilterMatchMode.IN },
            // full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            // Appointment_Status: { value: null, matchMode: FilterMatchMode.IN },
            // appointment_booked: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            // appointmentbooked_for: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
        setGlobalFilterValue('');
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(customers);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'program_tracker');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };


    const renderHeader = () => {
        return (
            <div className="flex justify-content-between header-flex">
                <div>
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                </div>
                <div className="export-button-container">
                    <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                </div>
            </div>
        );
    };

    const accept_tagging = (data) => {
        setLoading(true);
        axios.post(addTag, data,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 201){
                setLoading(false);
                setCustomers(response.data);
                // setIsMounted(!isMounted);
                toast.current.show({severity:'success', summary: 'Success', detail:'Tag Added', life: 3000});
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            setLoading(false);
        })         
    }

    const sendSeminarBody = (rowData) => {

        return(
            <div className="flex justify-content-center" style={{textAlign:'center'}}>
                <Button className="set-seminar-button" icon="pi pi-send" onClick={() => handleSendSeminar(rowData.contact_id, rowData.program)}/>
            </div>
        )
    }

    const handleSendSeminar = (contactId, program) => {
        let tag
        if (program === '21 Day Program'){
            tag = 'send 21 day rsvp form'
        }
        else{
            tag = 'send 6 week rsvp form'
        }
        
        const data = {
            contactId,
            locationId,
            tag
        }

        const message = `Are you sure you want to continue?`
        
        confirmDialog({
            message: message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept_tagging(data),
            reject
        });
    }

    const attendedSeminarBody = (rowData) => {

        return(
            <div className="flex justify-content-center" style={{textAlign:'center'}}>
                <Button className="set-attended-button" icon="pi pi-check-circle" onClick={() => handleAttendedSeminar(rowData.contact_id, rowData.program)}/>
            </div>
        )
    }

    const handleAttendedSeminar = (contactId, program) => {
        let tag
        if (program === '21 Day Program'){
            tag = 'attended 21 day nutrition seminar'
        }
        else{
            tag = 'attended 6 week nutrition seminar'
        }

        const data = {
            contactId,
            locationId,
            tag
        }

        const message = `Are you sure you want to continue?`
        
        confirmDialog({
            message: message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept_tagging(data),
            reject
        });
    }

    const wipeOutBody = (rowData) => {

        return(
            <div className="flex justify-content-center" style={{textAlign:'center'}}>
                <Button className="set-wipe-button" icon="pi pi-eraser" onClick={() => handleWipeOut(rowData.contact_id)}/>
            </div>
        )
    }

    const handleWipeOut = (contactId) => {
        const tag = 'wipe program data'

        const data = {
            contactId,
            locationId,
            tag
        }

        const message = `Are you sure you want to continue?`
        
        confirmDialog({
            message: message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept_tagging(data),
            reject
        });
    }


    const redirectBody = (rowData) => {

        return(
            <div className="flex justify-content-center" style={{textAlign:'center'}}>
                <Button className="set-redirect-button" icon="pi pi-external-link" onClick={() => handleRedirect(rowData.contact_id)}/>
            </div>
        )
    }
    

    const handleRedirect = (contactId) => {
        // window.location.href = `https://app.supafitgrow.com/v2/location/${locationId}/contacts/detail/${contactId}`; 
        const url = `https://app.simplexmarketingsystem.com/v2/location/${locationId}/contacts/detail/${contactId}`;
        window.open(url, '_blank');
      };

    const fullNameBody = (rowData) => {
        const fullName = rowData.full_name
        return(
            <div className='status'>
                {fullName? <span>{fullName}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const emailBody = (rowData) => {
        const db_outcome = rowData.outcome
        const email = rowData.email
        return(
            <div className='status'>
                {email? <span>{email}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const phoneBody = (rowData) => {
        const db_outcome = rowData.outcome
        const phone = rowData.phone
        return(
            <div className='status'>
                {phone? <span>{phone}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const rsvpBody = (rowData) => {
        const rsvp = rowData.seminar_rsvp
        return (
            rsvp ?
                <Tag value={rowData.seminar_rsvp} severity={getSeverity(rowData.seminar_rsvp)} /> :
                ''
        );
    };

    const rsvpFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={rsvp} itemTemplate={rsvpItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} maxSelectedLabels={1} placeholder="Any" className="p-column-filter" />;
    };

    const rsvpItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const attendedBody = (rowData) => {
        const attended_seminar = rowData.attended_seminar
        return ( 
            attended_seminar?
            <Tag value={rowData.attended_seminar} severity={getSeverity(rowData.attended_seminar)} /> :
            ''
        );
    };

    const attendedFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={attended} itemTemplate={attendedItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} maxSelectedLabels={1} placeholder="Any" className="p-column-filter" />;
    };

    const attendedItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const challengeBody = (rowData) => {
        const challenge_assignment = rowData.challenge_assignment
        return(
            <div className='status'>
                {challenge_assignment? <span>{challenge_assignment}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const challengeFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={challenges} itemTemplate={challengeItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} maxSelectedLabels={1} placeholder="Any" className="p-column-filter" />;
    };

    const challengeItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const programFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={programs} itemTemplate={programItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} maxSelectedLabels={1} placeholder="Any" className="p-column-filter" />;
    };

    const programItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const programBody = (rowData) => {
        const program = rowData.program
        let program_count;

        if (program == '6 Week Challenge'){
            program_count = rowData.week_challenge_count
        }
        else if (program == '6 Week Challenge - MOC'){
            program_count = rowData.week_challenge_MOC_count
        }
        else if (program == '21 Day Program'){
            program_count = rowData.day_program_count
        }
        else if (program == '6 Week Challenge - Bodyfat v1'){
            program_count = rowData.bodyfat_v1_count
        }
        else if (program == '6 Week Challenge - Bodyfat - MOC v1'){
            program_count = rowData.bodyfat_MOC_v1_count
        }
        else if (program == '6 Week Challenge - Bodyfat v2'){
            program_count = rowData.bodyfat_v2_count
        }
        else if (program == '6 Week Challenge - Bodyfat - MOC v2'){
            program_count = rowData.bodyfat_MOC_v2_count
        }

        return(
            <div className='status' id='program_id' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                {hovered ? 
                <span>{program_count}</span>
                :
                <span>{program}</span>
                }
            </div>
        )
    }

    const notesBody = (rowData) => {
        const notes = rowData.notes
        return(
            <div className='status'>
                {notes? <span>{notes}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const goalBody = (rowData) => {
        const goal_weight = rowData.goal_weight
        return(
            <div className='status'>
                {goal_weight? <span>{goal_weight}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const initialBody = (rowData) => {
        const initial_weight = rowData.initial_weight
        return(
            <div className='status blue-border'>
                {initial_weight? <span>{initial_weight}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const week1Body = (rowData) => {
        const week1_weight = rowData.week1_weight
        const program = rowData.program
        let editable;
        if (program === '6 Week Challenge - Bodyfat v1' || program === '6 Week Challenge - Bodyfat - MOC v1' || program === '6 Week Challenge - Bodyfat v2' || program === '6 Week Challenge - Bodyfat - MOC v2'){
            editable = false
        }
        else{
            editable = true
        }
        return(
            <div className={`'status' ${editable ? 'blue-border' : ''}`}>
                {week1_weight? <span>{week1_weight}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const week2Body = (rowData) => {
        const week2_weight = rowData.week2_weight
        const program = rowData.program
        let editable;
        if (program === '6 Week Challenge - Bodyfat v1' || program === '6 Week Challenge - Bodyfat - MOC v1'){
            editable = false
        }
        else{
            editable = true
        }
        return(
            <div className={`'status' ${editable ? 'blue-border' : ''}`}>
                {week2_weight? <span>{week2_weight}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const week3Body = (rowData) => {
        const week3_weight = rowData.week3_weight
        const program = rowData.program
        let editable;
        if (program === '6 Week Challenge - Bodyfat v2' || program === '6 Week Challenge - Bodyfat - MOC v2'){
            editable = false
        }
        else{
            editable = true
        }
        return(
            <div className={`'status' ${editable ? 'blue-border' : ''}`}>
                {week3_weight? <span>{week3_weight}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const week4Body = (rowData) => {
        const week4_weight = rowData.week4_weight
        const program = rowData.program
        let editable;
        if (program === '6 Week Challenge - Bodyfat v1' || program === '6 Week Challenge - Bodyfat - MOC v1'){
            editable = false
        }
        else{
            editable = true
        }
        return(
            <div className={`'status' ${editable ? 'blue-border' : ''}`}>
                {week4_weight? <span>{week4_weight}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const week5Body = (rowData) => {
        const week5_weight = rowData.week5_weight
        const program = rowData.program
        let editable;
        if (program === '6 Week Challenge - Bodyfat v1' || program === '6 Week Challenge - Bodyfat - MOC v1' || program === '6 Week Challenge - Bodyfat v2' || program === '6 Week Challenge - Bodyfat - MOC v2'){
            editable = false
        }
        else{
            editable = true
        }
        return(
            <div className={`'status' ${editable ? 'blue-border' : ''}`}>
                {week5_weight? <span>{week5_weight}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const week6Body = (rowData) => {
        const week6_weight = rowData.week6_weight
        const program = rowData.program
        return(
            <div className='status blue-border'>
                {week6_weight? <span>{week6_weight}</span>:<i className="pi"></i>}
            </div>
        )
    }

    const week1GainLossBody = (rowData) => {
        const week1_gain_loss = rowData.week1_gain_loss
        return(
            <div className='status'>
                {week1_gain_loss? <span>{week1_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week1CumGainLossBody = (rowData) => {
        const week1_cum_gain_loss = rowData.week1_cum_gain_loss
        return(
            <div className='status'>
                {week1_cum_gain_loss? <span>{week1_cum_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week2GainLossBody = (rowData) => {
        const week2_gain_loss = rowData.week2_gain_loss
        return(
            <div className='status'>
                {week2_gain_loss? <span>{week2_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week2CumGainLossBody = (rowData) => {
        const week2_cum_gain_loss = rowData.week2_cum_gain_loss
        return(
            <div className='status'>
                {week2_cum_gain_loss? <span>{week2_cum_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week3GainLossBody = (rowData) => {
        const week3_gain_loss = rowData.week3_gain_loss
        return(
            <div className='status'>
                {week3_gain_loss? <span>{week3_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week3CumGainLossBody = (rowData) => {
        const week3_cum_gain_loss = rowData.week3_cum_gain_loss
        return(
            <div className='status'>
                {week3_cum_gain_loss? <span>{week3_cum_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week4GainLossBody = (rowData) => {
        const week4_gain_loss = rowData.week4_gain_loss
        return(
            <div className='status'>
                {week4_gain_loss? <span>{week4_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week4CumGainLossBody = (rowData) => {
        const week4_cum_gain_loss = rowData.week4_cum_gain_loss
        return(
            <div className='status'>
                {week4_cum_gain_loss? <span>{week4_cum_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week5GainLossBody = (rowData) => {
        const week5_gain_loss = rowData.week5_gain_loss
        return(
            <div className='status'>
                {week5_gain_loss? <span>{week5_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week5CumGainLossBody = (rowData) => {
        const week5_cum_gain_loss = rowData.week5_cum_gain_loss
        return(
            <div className='status'>
                {week5_cum_gain_loss? <span>{week5_cum_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week6GainLossBody = (rowData) => {
        const week6_gain_loss = rowData.week6_gain_loss
        return(
            <div className='status'>
                {week6_gain_loss? <span>{week6_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const week6CumGainLossBody = (rowData) => {
        const week6_cum_gain_loss = rowData.week6_cum_gain_loss
        return(
            <div className='status'>
                {week6_cum_gain_loss? <span>{week6_cum_gain_loss}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const onCellSelect = (event) => {
        console.log(event)
        const column = event.field
        const program = event.rowData.program
        let validColumns;
        if (program === '6 Week Challenge - Bodyfat v1' || program === '6 Week Challenge - Bodyfat - MOC v1'){
            validColumns = ['initial_weight', 'week3_weight', 'week6_weight'];
        }
        else if (program === '6 Week Challenge - Bodyfat v2' || program === '6 Week Challenge - Bodyfat - MOC v2'){
            validColumns = ['initial_weight', 'week2_weight', 'week4_weight', 'week6_weight'];
        }
        else{
            validColumns = ['initial_weight', 'week1_weight', 'week2_weight', 'week3_weight', 'week4_weight', 'week5_weight', 'week6_weight'];
        }
        
        if (validColumns.includes(column)){
            if (event.value && event.value.match(/^\d+(\.\d+)?%$/)) {
                const floatValue = parseFloat(event.value.replace('%', ''));
                setValue(floatValue);
                setContactId(event.rowData.contact_id)
                setField(event.field)
                setDialogHeader(event.column.props.header)
                setVisible(true)
            }
            else{
                setValue(event.value)
                setContactId(event.rowData.contact_id)
                setField(event.field)
                setDialogHeader(event.column.props.header)
                setVisible(true)
            }
            
            // toast.current.show({ severity: 'info', summary: 'Cell Selected', detail: `Name: ${event.value}`, life: 3000 });
        }
    };

    const handleDialogClose = () =>{
        setValue(null)
        setContactId(null)
        setField(null)
        setDialogHeader(null)
        setVisible(false)
    }

    const accept = (data) => {
        setLoading(true);
        setVisible(false)
        axios.post(updateWeight, data,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 200){
                setLoading(false);
                setCustomers(response.data);
                // setIsMounted(!isMounted);
                setValue(null)
                setContactId(null)
                setField(null)
                setDialogHeader(null)
                setVisible(false)
                toast.current.show({severity:'success', summary: 'Success', detail:'Field updated', life: 3000});
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            setLoading(false);
        })         
    }

    const reject = () => {
        console.log('closed')
    }

    const handleSubmit = () => {
        const data = {
            contactId,
            field,
            value,
            locationId
        }

        const message = `Are you sure you want to update ${dialogHeader} weight?`
        
        confirmDialog({
            message: message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(data),
            reject
        });
    }

    const footerContent = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={handleDialogClose} className="p-button-text" />
            <Button label="Submit" icon="pi pi-check" onClick={handleSubmit} autoFocus />
        </div>
    );

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
      };

    const syncHandle = () =>{
        if(sync == false){
            axios.post(syncingUrl,{
                headers: {'Content-Type': 'application/json'},
            })
            .then((response)=>{
                if (response.status === 200){
                    setTrackIsMounted(!trackIsMounted)
                    toast.current.show({severity:'success', summary: 'Success', detail:'Sync has started', life: 2000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
                }
            })
        }
        else{
            toast.current.show({severity:'error', summary: 'Error', detail:'It is already syncing', life: 2000});
        }
    }

    const header = renderHeader();

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
            <ConfirmDialog />
            <Tooltip target=".export-buttons>button" position="bottom" />
            <div className="settings-container">
                <Button className="p-button-primary" label={sync ? "Synchronizing": "Sync"} icon={sync? "pi icon-size pi-spin pi-sync": "pi icon-size pi-sync"} onClick={syncHandle} />
            </div>
            <DataTable ref={dt} value={customers} scrollable scrollHeight="80vh" rowGroupMode="rowspan" groupRowsBy="program" sortMode="single" sortField="program" sortOrder={1} paginator rows={50} rowsPerPageOptions={[50, 100]} showGridlines loading={loading} removableSort dataKey="appointment_id" 
            cellSelection selectionMode="single" selection={selectedCell} onSelectionChange={(e) => setSelectedCell(e.value.rowData)} metaKeySelection={false} onCellSelect={onCellSelect}
                    filters={filters} globalFilterFields={['email', 'phone', 'full_name', 'program', 'challenge_assignment', 'notes', 'seminar_rsvp', 'attended_seminar']} header={header}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" emptyMessage="No contacts found.">
                <Column header="SR NO" className="padding-0" style={{ minWidth: '1rem'  }} frozen body={srNoBodyTemplate} />
                {/* <Column filterField="program" header="Program" showFilterMatchModes={false} filterMenuStyle={{ minWidth: '2rem' }} field="program" filter filterElement={programFilterTemplate} className="padding-0" style={{ minWidth: '14rem'  }} body={programBody}/> */}
                <Column filterField="full_name" header="Name" field="full_name" sortable filter filterPlaceholder="Search by name" className="padding-0" style={{ minWidth: '1rem' }} frozen body={fullNameBody}/>
                <Column filterField="program" header="Program" showFilterMatchModes={false} filterMenuStyle={{ minWidth: '2rem' }} frozen field="program" filter filterElement={programFilterTemplate} className="padding-0" style={{ minWidth: '14rem'  }} body={programBody}/>
                <Column filterField="phone" header="Phone" field="phone" sortable filter filterPlaceholder="Search by to" className="padding-0" style={{ minWidth: '1rem'  }} />
                <Column filterField="email" header="Email" field="email" sortable filter filterPlaceholder="Search by to" className="padding-0" style={{ minWidth: '2rem'  }} />        
                <Column filterField="challenge_assignment" header="Challenge" showFilterMatchModes={false} filterMenuStyle={{ minWidth: '2rem' }} filterElement={challengeFilterTemplate} field="challenge_assignment" sortable filter className="padding-0" style={{ minWidth: '2rem'  }} body={challengeBody}/>   
                {/* <Column filterField="notes" header="Notes" field="notes" sortable filter filterPlaceholder="Search by to" className="padding-0" style={{ minWidth: '20rem'  }} /> */}
                <Column filterField="goal_weight" header="Goal" field="goal_weight" sortable className="padding-0" style={{ minWidth: '2rem' }} />
                <Column filterField="initial_weight" header="Initial" field="initial_weight" sortable className="padding-0" style={{ minWidth: '2rem'} }body={initialBody} />
                <Column filterField="week1_weight" header="Week1" field="week1_weight" sortable className="padding-0" style={{ minWidth: '2rem' }} body={week1Body} />
                <Column filterField="week1_gain_loss" header="Gain/Loss" field="week1_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week1_cum_gain_loss" header="Cum. Gain/Loss" field="week1_cum_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week2_weight" header="Week2" field="week2_weight" sortable className="padding-0" style={{ minWidth: '2rem' }} body={week2Body}/>
                <Column filterField="week2_gain_loss" header="Gain/Loss" field="week2_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week2_cum_gain_loss" header="Cum. Gain/Loss" field="week2_cum_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week3_weight" header="Week3" field="week3_weight" sortable className="padding-0" style={{ minWidth: '2rem' }} body={week3Body}/>
                <Column filterField="week3_gain_loss" header="Gain/Loss" field="week3_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week3_cum_gain_loss" header="Cum. Gain/Loss" field="week3_cum_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week4_weight" header="Week4" field="week4_weight" sortable className="padding-0" style={{ minWidth: '2rem' }} body={week4Body}/>
                <Column filterField="week4_gain_loss" header="Gain/Loss" field="week4_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week4_cum_gain_loss" header="Cum. Gain/Loss" field="week4_cum_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week5_weight" header="Week5" field="week5_weight" sortable className="padding-0" style={{ minWidth: '2rem' }} body={week5Body}/>
                <Column filterField="week5_gain_loss" header="Gain/Loss" field="week5_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week5_cum_gain_loss" header="Cum. Gain/Loss" field="week5_cum_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week6_weight" header="Week6" field="week6_weight" sortable className="padding-0" style={{ minWidth: '2rem' }} body={week6Body} />         
                <Column filterField="week6_gain_loss" header="Gain/Loss" field="week6_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="week6_cum_gain_loss" header="Cum. Gain/Loss" field="week6_cum_gain_loss" sortable className="padding-0" style={{ minWidth: '2rem'  }} />
                <Column filterField="seminar_rsvp" header="Seminar RSVP Submitted" showFilterMatchModes={false} filterMenuStyle={{ minWidth: '2rem' }} filterElement={rsvpFilterTemplate} field="seminar_rsvp" sortable filter className="padding-0" style={{ minWidth: '2rem'  }} body={rsvpBody}/>
                <Column filterField="attended_seminar" header="Attended Seminar" showFilterMatchModes={false} filterMenuStyle={{ minWidth: '2rem' }} filterElement={attendedFilterTemplate} field="attended_seminar" sortable filter className="padding-0" style={{ minWidth: '2rem'  }} body={attendedBody}/>
                <Column filterField="notes" header="Notes" field="notes" sortable className="padding-0" style={{ minWidth: '30rem' }}/>
                <Column header="Send Seminar RSVP" filterMenuStyle={{ minWidth: '2rem' }} style={{ minWidth: '2rem' }} body={sendSeminarBody}/>
                <Column header="Attended Seminar" filterMenuStyle={{ minWidth: '2rem' }} style={{ minWidth: '2rem' }} body={attendedSeminarBody}/>
                <Column header="Wipe Program Data" filterMenuStyle={{ minWidth: '2rem' }} style={{ minWidth: '2rem' }} body={wipeOutBody}/>
                <Column header="Open in CRM" filterMenuStyle={{ minWidth: '2rem' }} style={{ minWidth: '2rem' }} body={redirectBody}/>
            </DataTable>
            <Dialog header={dialogHeader} visible={visible} onHide={handleDialogClose} footer={footerContent}
                style={{ width: '20vw' }} breakpoints={{'1600px': '30vw', '1100': '50vw', '960px': '75vw', '641px': '100vw' }}>
                <InputNumber value={value} onValueChange={(e) => setValue(e.value)} minFractionDigits={2} maxFractionDigits={5} useGrouping={false} min={0} showButtons/>
            </Dialog>
        </div>
    );
}